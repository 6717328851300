import * as React from 'react'
import { graphql } from 'gatsby'
import BlogIndexTemplate from '../components/pages/BlogIndexTemplate'

const NewsPage = ({ data: { allStrapiBlogs } }) => {
	return <BlogIndexTemplate allStrapiBlogs={allStrapiBlogs} />
}

export default NewsPage

export const pageQuery = graphql`
	query GetAllStrapiBlogs {
		allStrapiBlogs(sort: { fields: Published, order: DESC }) {
			nodes {
				Title
				Published(formatString: "")
				Summary
				BlogType
				Slug
				Featured
				Thumbnail {
					provider_metadata {
						public_id
					}
					formats {
						medium {
							url
						}
						small {
							url
						}
						thumbnail {
							url
						}
					}
				}
			}
		}
	}
`
